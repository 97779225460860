@import '../App.scss';

.bottom-controls {
  background-color: var(--ox-color-secondary--dark-80);
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  min-height: 62px;

  .group-left {
    display: flex;
    justify-content: center;
    align-items: center;

    .group-left-label {
      margin-right: 5px;
      color: white;
    }

    .group-left-pill-switch {
      margin: 0;
    }
  }

  .ox-button-group > * {
    margin: 0 5px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.2;
  }
}
