@import '../App.scss';

.error-card {
  position: absolute;
  top: 30vh;
  left: calc(50vw - 250px);
  width: 500px;
  padding: 40px;
  text-align: center;

  background-color: white;
  box-shadow: $shadow-small;
  border-radius: var(--ox-base-border-radius);

  .error-icon {
    width: 100%;
    color: #FF0000;
    font-size: 48px;
  }
}
