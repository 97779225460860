@import '../../App.scss';

div.entity-list {
  padding: 10px;

  .group-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .group-title {
      font-size: 16px;

      color: var(--ox-color-secondary);
      text-transform: uppercase;
      font-weight: bold;
      max-width: fit-content;
      flex: 1 auto;
      margin: 10px 1rem 10px 0;
    }

    .fill-line {
      flex: auto;
      border-width: 0.5px;
      color: var(--ox-color-secondary);
    }

    .remove-machine-icon {
      font-size: 16px;
      margin-left: 3px;
      cursor: pointer;
      font-weight: 500;
    }

    .machine-settings-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .add-machine {
    max-width: fit-content;
    cursor: pointer;
    margin-left: auto;

    .add-machine-text {
      color: black;
      margin-right: 10px;
      font-size: 14px;
    }

    &:hover {
      transform: translateY(-1px);
    }

    .add-machine-icon {
      font-size: 20px;
    }

    &.extra-margin-top {
      margin-top: 10px;
    }
  }

  p.client-title {
    margin: 0;
  }

  p.client-number {
    font-size: 18px;
    font-weight: bold;
  }

  p.customer-name {
    font-size: 8pt;
    font-weight: bold;
    color: var(--ox-color-secondary);
    margin: 15px 0;
  }
}

.entity-row-container {
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 4px;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: var(--ox-color-secondary--light-80);
  }

  &.textArea:hover, &.dateTimeField:hover, &.multiCheckBox:hover {
    background-color: unset;
  }

  .add-text {
    font-size: 8pt;
    text-decoration: underline;
    cursor: pointer;
  }
}
