@import '../App.scss';

.lds-ring {
    display: block;
    position: relative;
    margin: 100px auto;
    width: 64px;
    height: 64px;

    &.small {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0 10px 0 0;

        div {
            width: 14px;
            height: 14px;
            margin: 0;

            border-width: 3px;
        }
    }

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid var(--ox-color-secondary--dark-40);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--ox-color-secondary--dark-40) transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
