.pdf-viewer-container {
  margin-top: 100px;


  .page-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    .page-container__wrapper {
      position: relative;

      .page__text-layer-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        opacity: 0.2;
        line-height: 1.0;

        .page__text-layer_text-item {
          color: transparent;
          position: absolute;
          white-space: pre;
          cursor: text;
          transform-origin: 0% 0%;

          &::selection {
            background-color: #4a90e2;
          }
        }
      }
    }
  }
}
