@import './../App.scss';
$max-width: 1000px;

.overview {
  .toggle-menu {
    background-color: var(--ox-color-grey--light-40);

    nav {
      max-width: $max-width;
      padding: 0;
    }
  }

  .filter-container {
    position: relative;
    max-width: $max-width;
    margin: 0 auto 20px auto;

    .search-controls {
      display: flex;
      justify-content: space-between;
      padding-top: 22px;
    }

    .filter {
      padding: 10px 5px;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .document-row {
    cursor: pointer;
  }

  .document-row-assigned {
    cursor: not-allowed;
  }

  .centered {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 auto;
    max-width: $max-width;
    width: 100%;

    .start-annotating-button {
      align-self: flex-start;
      margin-bottom: 16px;
    }

    .select-document-type {
      align-self: center;
      margin-bottom: 24px;

      .document-type {
        border-radius: 12px;
        box-sizing: border-box;
        display: inline-block;
        height: 32px;
        margin-bottom: 6px;
        padding: 4px 8px;
        transition: all 200ms ease;

        .document-type-radio {
          cursor: pointer;
          margin-right: 6px;
          vertical-align: top;
        }

        .document-type-name {
          cursor: pointer;
        }
      }
    }
  }

  .annotated-documents {
    margin: 40px 0 40px 0;
    padding: 0;

    .document-list-title {
      margin: 16px 16px 16px 24px;
    }
  }
}

.loader-ellips {
  font-size: 20px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: 10px auto;
}

.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #555; /* change color here */
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}

.loader-ellips__dot:nth-child(3) {
  left: 1.5em;
}

.loader-ellips__dot:nth-child(4) {
  left: 3em;
}

@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slide {
  to {
    transform: translateX(1.5em)
  }
}

.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}
