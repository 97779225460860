@import './../../App.scss';

.info-sidebar {
  .sidebar-content-skeleton {
    color: white;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    .sidebar-content-skeleton-header {
      width: 170px;
      height: 20px;
      background-color: #e2e2e2;
      margin: 5px 0;
      border-radius: 15px;
    }

    .sidebar-content-skeleton-text {
      width: 260px;
      height: 10px;
      background-color: #e2e2e2;
      margin: 5px 0;
      border-radius: 15px;
    }
  }

  .attachment-item-container-skeleton {
    display: block;
    padding: 10px 0;

    .attachment-skeleton {
      width: 100%;
      color: #e2e2e2;
      padding: 2px 5px;
      display: flex;
      align-items: center;
      font-size: 10px;

      .attachment-icon {
        margin-left: 5px;
        text-align: center;
        font-size: 1.5em;
        width: 30px;
        font-weight: 500;
      }

      .attachment-name-skeleton {
        width: 250px;
        height: 10px;
        background-color: #e2e2e2;
        margin: 10px 2rem 10px 0;
        border-radius: 15px;
      }
    }
  }
}
