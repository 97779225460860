.audit-report-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  .select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > select {
      width: 700px;
    }
  }

  .ox-table-wrapper {
    width: 1400px;
  }

  .center-value {
    text-align: center;
  }
}
