.order-item-container {
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  padding: 20px;
  background-color: #fff;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;

  &.disable-row {
    cursor: not-allowed;
  }

  &.disable-row * {
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .order-item-extra-options {
      .drop-menu-button {
        color: var(--ox-color-secondary--dark-40);
      }
    }
  }

  .order-item-assigned-info {
    padding-right: 15px;
  }

  .order-item-customer-info {
    width: 100%;
    max-width: 600px;
    padding-right: 15px;

    .order-item-meta-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      h3 {
        font-size: 1.2rem;
      }

      h6 {
        margin: 0;
        padding-right: 5px;
      }

      div {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 16px;
      }
    }

    .meta-info-subject {
      font-size: 14px;
      display: block;
      cursor: inherit;
    }
  }

  .order-item-button {
    width: 100%;
    max-width: 300px;

    &:not(:last-child) {
      padding-right: 25px;
    }

    .order-item-button-importance-info {
      height: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span:not(:last-child) {
        margin-right: 10px;
      }
    }

    .order-item-status-info {
      height: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 15px;
    }
  }

  .order-item-extra-options {
    display: flex;
    align-items: center;

    button:not([disabled]) {
      cursor: pointer;
    }

    .drop-menu-button {
      cursor: pointer;
      padding: 10px;
      color: var(--ox-color-secondary--light-40);
    }

    .drop-menu {
      border: .0625rem solid #dde8e8;
    }
  }

  .site-indicator {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 0;
    border-color: transparent #fff transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
  }
}

.scale-out-ver-top {
  animation: scale-out-ver-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes scale-out-ver-top {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 0;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 100% 0;
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

