.info-icon-container {
  padding: 7px;
  margin-left: 3px;
  position: relative;

  .extra-info-bubbel {
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    background-color: #38B2AC;
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 10px;
  }
}
