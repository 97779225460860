@import './../App.scss';

.toggle-nav-menu {
  .toggle-nav-item {
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    padding: 20px;
  }
}
