.scroll-button-container {
  .btn-circle.btn-xl {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  .btn-top {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }

  .visible {
    opacity: 1;
  }

  .invisible {
    opacity: 0;
    cursor: auto;
  }
}
