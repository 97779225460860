.settings-container-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .settings-container {
    width: 100%;
    max-width: 980px;
    display: flex;
    justify-content: center;

    .sidebar {
      width: 25%;
      padding-right: 30px;

      .sidebar-nav {
        padding: 10px;
        border: 1px solid #e1e4e8;
        border-radius: 6px;

        .sidebar-nav-header {
          display: flex;

          .sidebar-nav__user-info {
            padding-left: 10px;
            line-height: 18px;

            .user-info__name {
              display: block;
              font-weight: 500;
              font-size: 18px;
            }
            .user-info__title {
              display: block;
              font-size: 13px;
            }
          }
        }
      }
    }

    .settings {
      width: 75%;

      .settings-header-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .settings-header__title {
          font-size: 18px;
          color: var(--ox-color-secondary);
          text-transform: uppercase;
          font-weight: 700;
          max-width: fit-content;
          flex: 1 1 auto;
          margin: 10px 1rem 10px 0;
        }

        .settings-header__line {
          flex: auto;
          border-width: .5px;
          color: var(--ox-color-secondary);
        }
      }

      .settings-items-wrapper {
        width: 500px;

        .settings-item-wrapper {
          .settings-item__label {
            display: block;
            font-weight: 500;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
