@media only screen and (min-width: 1750px) {
  .annotator {
    main.content {
      max-width: calc(100vw - 500px - 330px);
      width: calc(100vw - 500px - 330px);
    }
    .sidebar {
      &.entities {
        // Set width and min-width because otherwise the main container
        // Will consume the width of the sidenav and entity-nav
        width: 500px;
        min-width: 500px;
      }
    }
  }
}

@media only screen and (min-width: 1700px) {
  .annotator {
    main.content {
      max-width: calc(100vw - 450px - 330px);
      width: calc(100vw - 450px - 330px);
    }
    .sidebar {
      &.entities {
        width: 450px;
        min-width: 450px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .annotator {
    main.content {
      max-width: calc(100vw - 600px);
      width: calc(100vw - 600px);
    }

    .sidebar {
      &.documents {
        width: 200px;
        min-width: 200px;
      }
    }
  }

  .pdf > * {
    margin: 0;
  }
  .pdf {
    img {
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .annotator {
    main.content {
      max-width: calc(100vw - 500px);
      width: calc(100vw - 500px);
    }

    .sidebar {
      &.documents {
        width: 200px;
        min-width: 200px;
      }

      &.entities {
        width: 300px;
        min-width: 300px;
      }
    }
  }
}


