@import './components/App.scss';

html {
  scroll-behavior: smooth;
}

body {
  background-color: $silver-white;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, button {
  border: 0;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 4px 8px;
}

iframe {
  z-index: 1 !important;
}
