@import './../App.scss';

.mail {
  border: 1px solid #3b3b3b;
  margin: 100px auto 40px auto;
  box-sizing: content-box;
  box-shadow: $shadow-large;
  width: 90%;
  min-height: 1263px;
  background-color: white;
  color: black;

  .mail-header {
    background-color: var(--ox-color-secondary--light-80);
    padding: 15px 25px;

    .mail-header-item {
      display: flex;
      flex-direction: row;

      .mail-header-item-content {
        display: flex;
        flex-direction: column;
        min-width: 75px;
      }
    }
  }

  .mail-body {
    padding: 15px 25px;
    overflow-y: auto;
  }
}
