@import '../../App.scss';

$block-size: 38px;

.hotkey-block {
  z-index: 2;
  display: block;
  min-width: $block-size;
  height: $block-size;

  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: $block-size;
  vertical-align: middle;

  user-select: none;

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
}
