.login {
  margin: 25vh auto;
  width: 456px;

  &.card {
      padding: 20px;
      border-radius: 5px;
      background-color: white;
  }

  form {
    display: flex;
    flex-direction: column;

    .input {
      margin-bottom: 28px;
      padding-left: 24px;
    }

    .submit-button {
      align-self: flex-end;
      cursor: pointer;
    }
  }
}
