.ox-modal.generic-modal.wide {
  width: 50vw;
}

.ox-modal {
  &__large {
    min-height: 50vh;
  }

  &__sub-title {
    margin-top: 0;
    color:black;
    margin-bottom: 2em;
  }

  .ox-form__field {
    max-width: 50%;
  }
}
