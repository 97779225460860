$font-size: 16px;
$color: var(--ox-color-secondary--dark-40);

.search-bar {
  position: relative;
  width: 100%;

  .line-input {
    position: absolute;
    bottom: 0;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $color;
    transform: scaleX(1);
    transition: all .5s ease-in-out
  }

  .search-icon {
    color: $color;
  }

  input {
    font-size: $font-size;
    border: none;
    height: 60px;
    line-height: 60px;
    width: 100%;
    display: block;
    outline: 0;
    border-radius: 0;
    max-width: 100%;
    background: #f9f9f9 0 0;
  }

  input::-webkit-input-placeholder {
    color: $color;
    font-size: $font-size;
    text-transform: uppercase
  }

  input:-moz-placeholder {
    color: $color;
    font-size: $font-size;
    text-transform: uppercase
  }

  input::-moz-placeholder {
    color: $color;
    font-size: $font-size;
    text-transform: uppercase
  }

  input:-ms-input-placeholder {
    color: $color;
    font-size: $font-size;
    text-transform: uppercase
  }

  input:focus + .line-input {
    transform: scaleX(0)
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    border: none;
    background: 0 0;
    outline: 0
  }
}
