@import './../App.scss';

header.navbar {
  box-shadow: $shadow-small;
  z-index: 4;

  .header-container {
    max-width: 100%;

    .spacer {
      border-right: 1px solid #888888;
      height: 20px;
      margin: 0 15px 0 15px;
    }

    .logo {
      i {
        font-weight: 900;
        font-style: italic;
      }

      p {
        margin: 0;
        font-weight: 400;
        font-style: italic;
        color: #888888;
      }
    }

    .header-group-wrapper {
      display: flex;
    }
  }
}
