@import './../App.scss';

.pdf {
  margin-top: 100px;
  background: var(--ox-color-secondary--dark-40);

  & > * {
    border: 1px solid #3b3b3b;
    margin: 40px auto;
    box-sizing: content-box;
    box-shadow: $shadow-large;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  p, span, b, i {
    // Initial flashing CTA color
    border-color: var(--ox-color-primary);
    transition: all 0.2s ease-in-out;
    position: relative;
    user-select: none;

    &.marked {
      border-radius: 2px;
      padding: 2px;
      cursor: pointer;
      opacity: 0.9;
    }

    &.temp-mark, & b.temp-mark, & i.temp-mark {
      background-color: var(--ox-color-primary);
      color: white;
      opacity: 0.9;
    }

    &.selectable {
      border: 2px solid rgba(#636363, 0.1);
      border-radius: 3px;
      cursor: pointer;
      transition: border-color 0.2s ease-in-out;

      &:hover {
        border-color: var(--ox-color-primary);
      }
    }
  }
}
