.datetime-container {
  display: flex;

  input::placeholder {
    opacity: 0.7;
  }
}

.entity-info {
  .ox-form__label {
    &-bold {
      font-size: 14px;
    }
  }
}

