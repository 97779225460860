.progress-label {
  display: flex;
  align-items: center;
  font-size: 14px;

  span {
    padding-right: 5px;
  }

  .status-info {
    font-weight: bold;
  }
}
