
.ox-button-group {
  &--loading {
    .ox-loader {
      position: unset;
      transform: unset;
      height: 24px;

      &-button {
        background: none;
        display: inline-block;
      }
    }

    .ox-button--loading {
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }
}
