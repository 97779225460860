@import './../App.scss';

.user-avatar {
  align-items: center;
  background-color: var(--ox-color-primary);
  border-radius: 50%;
  box-shadow: $shadow-small;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 32px;
  justify-content: center;
  transition: all 200ms ease;
  width: 32px;
}

.user-avatar-empty {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ox-color-secondary);

  span {
    font-size: 16px;
    color: white;
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
}
