.test-container {
  .ox-form__field {
    max-width: 100% !important;
  }

  .entity-info {
    margin-left: 0;
    max-width: 250px;
  }
}
