$entity-transition: all 0.2s ease-in-out;
$block-size: 38px;

p.annotation-text {
  color: black;
  font-weight: 700;
  word-wrap: break-word;
}

.entity-row {
  display: flex;

  &.typeAhead {
    .disable-typeahead {
      cursor: not-allowed;
    }
  }

  &.dateTimeField {
    .hotkey-placeholder-block {
      display:none;
    }
  }

  &.multiCheckBox {
    .hotkey-placeholder-block {
      display: none;
    };
  }

  &.measureField {
    .hotkey-placeholder-block {
      display: none;
    }
  }

  &.dropDown {
    align-items: center;
  }

  &.checkBox {
    .hotkey-placeholder-block {
      display:none;
    }
   .entity-info {
     margin-left: calc((38px - 1.5em) / 2);
     label {
       margin-left:10px;
     }
   }
  }

  .form-validation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .validation-icon {
      font-size: 14px;
    }
  }

  .hotkey-placeholder-block {
    display: block;
    min-width: $block-size;
    height: $block-size;

    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: $block-size;
    vertical-align: middle;
    border: 1px solid var(--ox-color-secondary);
    color: var(--ox-color-secondary);

    user-select: none;
    cursor: pointer;

    transition: $entity-transition;
  }

  .entity-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;

    .edit-container {
      padding-top: 1px;
      position: relative;
      display: flex;
      justify-content: space-between;
      line-height: 1.5;

      // transition: $entity-transition;

      .metro-line {
        position: absolute;
        height: 100%;
        left: -30px;
        top: -1px;

        .line {
          display: block;
          height: 100%;
          border-left: 4px solid;
        }
      }

      .metro-line {
        opacity: 0;
      }

      &.empty {
        line-height: 0;
      }

      &.metro {
        line-height: 30px;

        .annotation-text {
          &.editing {
            margin: 5px 0;
          }
        }

        .edit-icon, .delete-icon {
          &.editing {
            line-height: 48px;
          }
        }

        .metro-line {
          position: absolute;
          opacity: 1;
          height: 100%;
          left: -30px;
          top: -1px;

          .line {
            position: absolute;
            display: block;
            height: 100%;
            border-left: 4px solid;
          }

          .dot {
            position: absolute;
            top: calc(50% - 5px);
            left: -3px;
            display: block;
            width: 10px;
            height: 10px;
            border: 3px solid;
            border-radius: 50%;
            background-color: white;
          }

          .last {
            height: 50%;
          }
        }
      }

      .typeahead-container {
        width: auto;
        position: relative;

        &.editing {
          width: 100%;
        }

        &.textArea {
          width: 100%;

          textarea {
            font-size: 14px;
            line-height: 15px;
            height: auto;
            resize: none;
          }
        }

        .typeahead-suggestions {
          margin: 0;
          padding: 0;
          list-style-type: none;
          position: absolute;
          z-index: 5;
          top: 100%;
          left: 0;
          right: 0;
          border: 1px solid #d4d4d4;
          background-color: #f9f9f9;

          .suggestion-item {
            padding: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            .upper-items {
              display: flex;
              justify-content: space-between;
              align-items: center;
              line-height: 10px;
            }

            .primary-item {
              margin-bottom: 5px;
              font-size: 16px;
              line-height: 16px;
              font-weight: bold;
            }

            .secondary-item {
              font-size: 14px;
            }

            .tertiary-item {
              font-size: 10px;
              font-weight: normal;
              text-align: center;
              color: #121212;
            }

            &.selected {
              background-color: #c6d9d9;
            }
          }

          .suggestion-item:hover {
            background-color: #c6d9d9;
          }
        }
      }
    }

    .icon-actions {
      display: flex;
      justify-content: space-between;
    }

    .hidden {
      display: none;
    }

    .entity-name {
      font-size: 14px;
      line-height: 14px;
      vertical-align: top;
      user-select: none;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-remove-button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 900;
        margin-right: 5px;
      }

      &.full-height {
        line-height: 39px;
      }

      &.editing {
        font-size: 0;
        line-height: 0;
      }
    }

    .annotation-text {
      background-color: transparent;
      outline: none;
      padding: 0;
      font-weight: bold;
      font-size: 1rem;
      border-color: transparent;
      width: auto;
      resize: none;
      // transition: $entity-transition;

      &.empty {
        font-size: 0;
        line-height: 0;
      }

      &.editing {
        padding: 9px 5px;
        background-color: white;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        width: 100%;
      }
    }

    .edit-icon, .delete-icon {
      font-size: 14px;
      line-height: 14px;
      opacity: 0;
      padding-right: .4rem;
      cursor: pointer;
      // transition: $entity-transition;

      &.shown {
        opacity: 1;
      }

      &.editing {
        line-height: 38px;
      }
    }
  }
}

.ox-form__feedback.no-error {
  color: var(--ox-color-secondary--dark-40) ;
}

.entity-name, .entity-row {
  .edit-icon, .delete-icon {
    display:none;
  }

  &:hover {
    .edit-icon, .delete-icon {
      display: inline-block;
    }
  }
}
