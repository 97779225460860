$shadow-small: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
$shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
$shadow-large: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);
$shadow-extra-large: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04);
$shadow-extra-extra-large: 0 25px 50px -12px rgba(0, 0, 0, .25);
$shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, .06);

$silver-white: #f9f9f9;
$white: #fff;
$black: #000;
$black-semi-transparent: rgba(0, 0, 0, .5);

::selection {
  background-color: var(--ox-color-secondary--light-40);
  color: var(--ox-color-secondary--dark-80);
}

a {
  text-decoration: none;
}

h2 {
  margin: 20px 0;
}

.error-shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.disabled {
  // TODO: extract color to a colors file
  background-color: #EAEDED;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
