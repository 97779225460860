@import '../App.scss';

$success: var(--ox-color-success);
$error: var(--ox-color-error);
$warning: var(--ox-color-warning);
$info: var(--ox-color-update);
$debug: var(--ox-color-secondary);

.feedback-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 380px;
  z-index: 2;

  .feedback-type {
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px 8px 0 0;

    &[message-type='SUCCESS'] {
      border: 3px solid $success;
      background-color: $success;
    }

    &[message-type='ERROR'] {
      border: 3px solid $error;
      background-color: $error;
    }

    &[message-type='WARNING'] {
      border: 3px solid $warning;
      background-color: $warning;
    }

    &[message-type='INFO'] {
      border: 3px solid $info;
      background-color: $info;
    }

    &[message-type='DEBUG'] {
      border: 3px solid $debug;
      background-color: $debug;
    }
  }

  .feedback-card {
    height: auto;
    padding: 10px 10px 10px 20px;
    margin: 0 10px 10px 10px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    text-align: left;
    display: flex;
    justify-content: space-between;

    .close-button {
      background-color: #dadada;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      box-sizing: border-box;
      color: #4b4b4b;
      cursor: pointer;
      display: block;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      margin-left: 15px;
      min-width: 24px;
      text-align: center;
      transition: all 200ms ease;
    }
  }
}
