.entity-info {
  .ox-form__group {
    margin-bottom: 0;
  }
  .ox-form__label {
    margin-bottom:1px;
    font-size:12px;
  }
  #selection {
    height: 2rem;
    padding: 0 0 0 0.25rem;
  }
}
