.annotator-container {
  position: relative;
  height: inherit;
  max-height: inherit;
  background-color: #455A64; }
  .annotator-container .annotator-pages-container {
    height: inherit;
    max-height: inherit;
    overflow-y: auto; }
    .annotator-container .annotator-pages-container .annotator-pages {
      padding: 50px 0; }

.fab-group-container {
  position: absolute;
  bottom: 10px;
  right: 40px;
  text-align: center;
  padding: inherit;
  width: 50px;
  display: inline-block; }
  .fab-group-container .fab-group__list {
    z-index: 3;
    list-style: none;
    margin: 0;
    padding: 0; }
    .fab-group-container .fab-group__list .fab-group__list-item .fab-group__button {
      margin: 10px 5px;
      cursor: pointer;
      display: flex;
      background-color: #f7f7f7;
      color: #a7a7a7;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
      .fab-group-container .fab-group__list .fab-group__list-item .fab-group__button .fab-group__button-icon {
        width: 20px;
        height: 20px; }
      .fab-group-container .fab-group__list .fab-group__list-item .fab-group__button:hover {
        text-decoration: none;
        color: #555;
        background: #f5f5f5; }
      .fab-group-container .fab-group__list .fab-group__list-item .fab-group__button:active {
        transform: scale(0.95); }

.loader-container {
  position: absolute;
  left: calc(50% - 40px); }
  .loader-container .loader__lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
    .loader-container .loader__lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px; }
    .loader-container .loader__lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #000;
      margin: -4px 0 0 -4px; }
    .loader-container .loader__lds-roller div:nth-child(1) {
      animation-delay: -0.036s; }
    .loader-container .loader__lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px; }
    .loader-container .loader__lds-roller div:nth-child(2) {
      animation-delay: -0.072s; }
    .loader-container .loader__lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px; }
    .loader-container .loader__lds-roller div:nth-child(3) {
      animation-delay: -0.108s; }
    .loader-container .loader__lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px; }
    .loader-container .loader__lds-roller div:nth-child(4) {
      animation-delay: -0.144s; }
    .loader-container .loader__lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px; }
    .loader-container .loader__lds-roller div:nth-child(5) {
      animation-delay: -0.18s; }
    .loader-container .loader__lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px; }
    .loader-container .loader__lds-roller div:nth-child(6) {
      animation-delay: -0.216s; }
    .loader-container .loader__lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px; }
    .loader-container .loader__lds-roller div:nth-child(7) {
      animation-delay: -0.252s; }
    .loader-container .loader__lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px; }
    .loader-container .loader__lds-roller div:nth-child(8) {
      animation-delay: -0.288s; }
    .loader-container .loader__lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px; }

@keyframes lds-roller {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ocr-info-container {
  position: absolute;
  top: 10px;
  left: 10px; }
  .ocr-info-container .ocr-info__icon {
    width: 20px;
    height: 20px;
    cursor: help; }
  .ocr-info-container .ocr-info__icon-rotate {
    animation: rotate infinite 2s linear; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.page {
  display: flex;
  justify-content: center; }
  .page:not(:last-child) {
    padding-bottom: 40px; }
  .page .page__container {
    position: relative; }
    .page .page__container .page__canvas-container {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
    .page .page__container .page__text-layer-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      line-height: 1.0; }
      .page .page__container .page__text-layer-container .token-container {
        position: absolute;
        display: inline;
        white-space: pre;
        cursor: inherit;
        transform-origin: 0 0; }

.mark-container {
  opacity: .5;
  cursor: pointer;
  position: relative;
  border-radius: 3px; }
  .mark-container .mark__token {
    color: transparent; }

.token__text-item {
  color: transparent;
  white-space: nowrap;
  /* Thanks to Herb Caudill comment */ }

.selection-container.annotating-mode {
  user-select: none;
  cursor: crosshair; }

.selection-container.text-selection-mode {
  cursor: text; }
  .selection-container.text-selection-mode::-moz-selection {
    background: rgba(0, 0, 255, 0.2);
    color: transparent; }
  .selection-container.text-selection-mode::selection {
    background: rgba(0, 0, 255, 0.2);
    color: transparent; }

.selection-container.normal-mode {
  cursor: default; }

.selection-container .selection__rectangle {
  opacity: 0.3;
  border: 1px dashed #000;
  position: absolute; }

.tooltip-container {
  position: relative; }
  .tooltip-container:hover .tooltip__text {
    visibility: visible; }
  .tooltip-container .tooltip__text {
    visibility: hidden;
    width: max-content;
    max-width: 200px;
    color: #fff;
    background-color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 0;
    opacity: 0.65; }
