.skeleton-list {
  padding: 10px 20px;

  .skeleton-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .skeleton-header-title {
      width: 170px;
      height: 20px;
      background-color: #e2e2e2;
      margin: 10px 1rem 10px 0;
      border-radius: 15px;
    }

    .skeleton-header-fill-line {
      flex:auto;
      border-width: 0.5px;
      color: #e2e2e2;
    }
  }

  .skeleton-item-container {
    padding: 4px;
    margin-bottom: 4px;

    .skeleton-hotkey-block {
      display: block;
      background-color: #e2e2e2;
      min-width: 38px;
      height: 38px;
    }

    .skeleton-item-row {
      display: flex;
      margin-bottom: 4px;

      .skeleton-info-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;

        .skeleton-info-name {
          margin-top: 2px;
          width: 130px;
          height: 10px;
          background-color: #e2e2e2;
          border-radius: 15px;
        }

        .skeleton-info-value {
          margin-bottom: 2px;
          width: 200px;
          height: 14px;
          background-color: #e2e2e2;
          border-radius: 15px;
        }
      }
    }
  }
}
