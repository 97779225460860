.sidebar-nav-container {
  padding: 20px 10px;
  border: none;
  box-shadow: none;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #f9f9f9;

  .nav-list {
    display: flex;
    justify-content: space-evenly;

    .nav-item {
      cursor: pointer;
    }
  }
}
