.site-status-container {
  margin-right: 20px;

  .site-status-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;

    .site-name {
      color: #121212;
    }
  }

  .drop-menu {
    transform: translateX(-100%) translateX(5.75rem);
  }
}
