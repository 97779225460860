@import './../App.scss';

$header-height: 80px;
$document-controls-height: 62px;

.annotator {
  align-items: stretch;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  max-height: calc(100vh - #{$header-height});
  outline: none;
  position: relative;

  .sidebar {
    overflow-y: auto;
    max-height: calc(100vh - #{$header-height});
    display: flex;
    flex-direction: column;

    &.documents {
      background-color: var(--ox-color-secondary--dark-80);
      width: 330px;
      min-width: 330px;
    }

    &.entities {
      background-color: $silver-white;
      width: 400px;
      min-width: 400px;
    }

    h2.title {
      margin: 0 20px;

      &.white {
        color: white;
      }
    }

    .suggestions-button {
      display: block;
      margin: 10px 20px;

      .icon {
        margin-right: 5px;
      }
    }
  }

  .content-form-wrapper {
    display: flex;
  }

  main.content {
    background-color: var(--ox-color-secondary--dark-40);
    height: calc(100vh - #{$header-height});
    position: relative;
    display: flex;
    flex-grow: 1;
    max-width: calc(100vw - 400px - 330px);
    width: calc(100vw - 400px - 330px);

    h3 {
      background-color: var(--ox-color-secondary--dark-80);
      color: white;
      padding: 20px;
      font-size: 16px;
      width: 100%;
      position: absolute;
      z-index: 1;

      .download-button {
        color: white;
        float: right;
        cursor: pointer;
        display: block;
      }
    }

    .text {
      height: calc(100vh - #{$header-height} - #{$document-controls-height});
      overflow-y: auto;
      width: 100%;

      .document-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        max-height: 72px;
      }

      .document-actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        .document-actions-view {
          display: flex;
          align-items: center;
          margin-right: 15px;

          .document-actions-view-label {
            margin-right: 5px;
            font-weight: 200;
            font-size: 14px;
          }

          .document-actions-view-switch {
            margin: 0;
          }
        }

        .attachment-item-container {
          margin-right: 1em;

          .attachment-type {
            color: white;
            background-color: var(--ox-color-secondary--dark-80);
            display: flex;
            box-shadow: none;
            margin-right: 10px;
            font-size: 12px;
            background-size: 1rem;
            padding-right: 1.5rem;
            background-position: right 0.25rem center;
            height: 2rem;
          }
        }
      }

      .not-supported-message {
        margin: 100px 0;
        width: 100%;
        text-align: center;
        opacity: 0.7;
        color: white;

        .icon {
          font-size: 32px;
        }

        p {
          font-size: 24px;
          font-weight: bold;
        }
      }

      .pf {
        box-shadow: none;
        margin: 0;
      }
    }
  }
}


/*
  In this file we will define certain breakpoints to handle other screen resolutions
  This file will only contain media queries on exact points.
  Never define normal css here because it will override all CSS.
  Import this file after all normal css.
 */
@import '../../styles/breakpoints';
