.ox-is-loading {
  &--text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1em;
    line-height: 1em;
    .ox-loader {
      margin: 0 1em;
      position: unset;
      transform:unset;
    }
  }
}
