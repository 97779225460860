@import './../../App.scss';

.info-sidebar {
  .sidebar-content {
    color: white;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    h4 {
      color: white;
      padding: 5px 0;
      font-size: 1rem;
    }

    p {
      padding: 5px 0;
      margin: 0;
      font-size: 14px;
    }
  }

  .sidebar-title {
    border-top: 1px solid white;
    color: #888888;
    font-weight: bold;

    &--content {
      width: 100%;
      padding: 15px 10px;
    }
  }

  .title {
    padding: 25px 0;
    height: 100%;
    max-height: 72px;

    a {
      text-transform: uppercase;
      color: white;
      font-size: 12pt;
      font-weight: bold;
    }

    span {
      font-size: 26pt;
      margin-right: 10px;
    }
  }

  .attachment-wrapper {
    display: flex;

    &:hover {
      background-color: var(--ox-color-secondary--dark-60);
      cursor: pointer;
    }

    .attachment-item-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      margin-right: 10px;

      .attachment {
        width: 100%;
        color: white;
        padding: 15px 5px;
        display: flex;
        align-items: center;
        font-size: 10px;

        .attachment-icon {
          margin-left: 5px;
          text-align: center;
          font-size:1.5em;
          width: 30px;
          font-weight: 500;
        }

        .attachment-name {
          margin-left: 10px;
          font-size: 12px;
          font-weight: bold;
          cursor: inherit;
          word-wrap: break-word;
          width: 100%;
        }

        .select.ox-form__field--small {
          padding-right: 0;
        }
      }

    }

    &.selected {
      background-color: var(--ox-color-secondary--dark-40);
      border-left: 5px solid white;

      .attachment-icon {
        margin-left: 0;
      }
    }
  }


.focus {
  background-color: var(--ox-color-secondary--dark-40);
}

.order-badge {
  font-size: 8px;
}

.custom-select {
 font-size: 9px;
 -webkit-appearance: none;
}

.doc-type-option {
  text-transform: capitalize;
}

}
