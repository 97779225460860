.sidebar.entities {
  padding: 0;
}

.extra-info-container {
  display: flex;
  padding: 10px;
  margin: 0 10px;
  border: 1px #22547C solid;
  background: rgba(128, 182, 225, 0.5);
  border-radius: 5px;

  & > span {
    color: #22547C;
    font-size: 14px;
  }

  & > label {
    font-size: 14px;
    line-height: 16px;
    padding: 0 10px;
    color: #22547C;
  }
}
